import { ActionBus } from '../../../_base/actionBus/ActionBus';
import { SessionStorage } from '../../../models/session/SessionStorage';
import { ChatMessage, MessageDirections } from '../../../models/chat/ChatMessage';
import { ChatVM, GroupedMessages } from './ChatVM';
import { SendMessage } from '../../../actions/chat/SendMessage';
import { GetMessages } from '../../../actions/chat/GetMessages';
import { LocalDateTime } from '../../../_base/time/LocalDateTime';

export interface ChatView {
    modelChanged(model: ChatVM);
}

export class ChatPresenter {
    private model = new ChatVM();
    constructor(public view: ChatView, public actionBus: ActionBus, public sessionStorage: SessionStorage) {}

    async start() {
        this.set( { isLoading: true });
        let messages = await this.actionBus.query(new GetMessages());
        let unitAuthor = await this.sessionStorage.get().then(session => session.unit?.association.address.street.toString() ?? 'Tú');
        this.set( { messages: messages.reverse(), isLoading: false, author: unitAuthor });
        this.set( { groupedMessages: this.groupMessagesByDate(this.model.messages) });
    }

    async sendMessage () {
        if (this.model.prompt.trim().length === 0) return;
        let sentMessage = new ChatMessage(this.model.prompt.trim(), MessageDirections.Received, LocalDateTime.fromDate(new Date()));
        this.set({ messages: [...this.model.messages, sentMessage], isWriting: false });
        this.set({ prompt: '', isWriting: true });
        let session = await this.sessionStorage.get();
        let manager = session?.user?.units.find( unit => unit.id === session.currentUnitId)?.manager;
        let newMessage: ChatMessage;
        if (manager) {
            try {
                newMessage = await this.actionBus.exec(new SendMessage(manager.id, sentMessage.body));
            } catch (e) {
                newMessage = new ChatMessage('Lo siento, hubo un error. Intenta nuevamente en unos minutos o ponte en contacto con la administración.', MessageDirections.Sent);
            }
            this.set({ messages: [...this.model.messages, newMessage], isWriting: false });
            this.set({ groupedMessages: this.groupMessagesByDate(this.model.messages) });
        }
    }

    setPromptText = (text: string) => this.set({ prompt: text });

    groupMessagesByDate(messages: ChatMessage[]): GroupedMessages[] {
        const sortedMessages = messages.sort((a, b) => {
            const aDate = new Date(a.at.year, a.at.month - 1, a.at.day, a.at.hours, a.at.minutes, a.at.seconds, a.at.milliseconds);
            const bDate = new Date(b.at.year, b.at.month - 1, b.at.day, b.at.hours, b.at.minutes, b.at.seconds, b.at.milliseconds);
            return aDate.getTime() - bDate.getTime();
        });

        if (sortedMessages.length == 0) return [];
        const groups: GroupedMessages[] = [];
        let currentGroup: GroupedMessages = { date: sortedMessages[0].at.toDate().toISO8601String(), messages: [] };

        for (const message of sortedMessages) {
            try {
                if (message.at.toDate().toISO8601String() !== currentGroup.date) {
                    groups.push(currentGroup);
                    currentGroup = { date: message.at.toDate().toISO8601String(), messages: [] };
                }
                currentGroup.messages.push(message);
            } catch (e) {
                console.log(e);
            }
        }
        groups.push(currentGroup);
        return groups;
    }

    generateShortUUID(): string {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let uuid = '';

        for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            uuid += chars[randomIndex];
        }
        return uuid;
    }

    private set<K extends keyof ChatVM>(changes: Pick<ChatVM, K>) {
        this.model = Object.assign(this.model, changes);
        this.view.modelChanged(this.model);
    }
}
